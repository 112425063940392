import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default class InlineVideo {

    constructor(el, props = {}) {

        this.$el = $(el);
        this.$video = this.$el.is('video') ? this.$el : this.$el.find('video');
        this.video = this.$video.get(0);

        this.hasInited = false;

        this.$el.find('img').css({
            visibility: 'hidden'
        });

        if (!this.video || !this.video.canPlayType || !this.video.canPlayType('video/mp4')) {
            console.warn('No video');
            this.cantPlayVideo();
            return;
        }

        this.videoIsPlaying = false;
        this.videoFallbackTimer = null;

        this.sources = {
            desktop: props.sources ? props.sources.desktop || props.sources.mobile || null : null,
            mobile: props.sources ? props.sources.mobile || props.sources.desktop || null : null
        };

        if (!this.sources.desktop && !this.sources.mobile) {
            console.warn('No sources');
            this.cantPlayVideo();
            return;
        }

        this.onLoadStart = this.onLoadStart.bind(this);
        this.onTimeUpdate = this.onTimeUpdate.bind(this);
        this.onFallbackTimer = this.onFallbackTimer.bind(this);
        this.onResize = this.onResize.bind(this);

        this.$el.data('inlineVideo', this);

        this.initObserver();
    }

    getVideoFormat() {
        const { name: breakpoint } = Viewport.breakpoint;
        const isSmall = ['none', 's', 'sp', 'm', 'mp'].indexOf(breakpoint) > -1;
        if (isSmall) {
            return 'mobile';
        }
        return 'desktop';
    }

    initObserver() {
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                console.log({ entry });
                const { intersectionRatio } = entry;
                if (intersectionRatio > 0) {
                    this.playVideo();
                } else {
                    this.pauseVideo();
                }
            });
        }, {
            rootMargin: '0px',
            threshold: 0
        });
        this.observer.observe(this.video);
        console.log('observer initialised', this.video);
    }

    initPlay() {
        if (this.hasInited) {
            return;
        }
        this.hasInited = true;
        this.maybeSwapSource();
        this.playAndCatch();
        Viewport.on('resize', this.onResize);
    }

    destroy() {
        if (this.observer) {
            this.observer.unobserve(this.$el.get(0));
            this.observer.disconnect();
            this.observer = null;
        }
        this.killVideo();
        this.$el
            .css({
                visiblity: ''
            })
            .removeClass('js-is-playing js-cant-play-video');
        this.$el.find('img').css({
            visibility: ''
        });
    }

    stopFallbackTimer() {
        if (!this.video || !this.videoFallbackTimer) {
            return;
        }
        clearTimeout(this.videoFallbackTimer);
        this.videoFallbackTimer = null;
    }

    startFallbackTimer(interval) {
        this.stopFallbackTimer();
        this.videoFallbackTimer = setTimeout(this.onFallbackTimer, interval);
    }

    addVideoEventListeners() {
        if (!this.video) {
            return;
        }
        this.video.addEventListener('timeupdate', this.onTimeUpdate);
        this.video.addEventListener('durationchange', this.onLoadStart);
        this.video.addEventListener('loadedmetadata', this.onLoadStart);
        // this.video.addEventListener('loadeddata', this.onLoadStart);
        // this.video.addEventListener('canplay', this.onLoadStart);
    }

    removeVideoEventListeners() {
        if (!this.video) {
            return;
        }
        this.video.removeEventListener('timeupdate', this.onTimeUpdate);
        this.video.removeEventListener('durationchange', this.onLoadStart);
        this.video.removeEventListener('loadedmetadata', this.onLoadStart);
        // this.video.removeEventListener('loadeddata', this.onLoadStart);
        // this.video.removeEventListener('canplay', this.onLoadStart);
    }

    pauseVideo() {
        console.log('pause video');
        if (!this.video || !this.videoIsPlaying) {
            return;
        }
        this.video.pause();
    }

    playVideo() {
        console.log('play video');
        if (!this.hasInited) {
            this.initPlay();
            return;
        }
        if (!this.video || (this.hasInited && !this.videoIsPlaying)) {
            return;
        }
        if (!this.videoIsPlaying) {
            return;
        }
        this.video.play();
    }

    killVideo() {
        this.stopFallbackTimer();
        this.removeVideoEventListeners();
        if (!this.video) {
            return;
        }
        this.video.pause();
        this.videoIsPlaying = false;
    }

    maybeSwapSource() {

        if (!this.video) {
            return;
        }

        const currentFormat = this.getVideoFormat();
        const currentSource = this.video.getAttribute('src');
        const wantedSource = this.sources[currentFormat];

        if (!wantedSource || currentSource === wantedSource) {
            return;
        }

        this.killVideo();
        this.video.src = wantedSource;

        if (!this.video.canPlayType) {
            this.cantPlayVideo();
            return;
        }

        this.playAndCatch();
    }

    playAndCatch() {

        this.addVideoEventListeners();

        this.video.muted = true;

        this.startFallbackTimer(2000);

        let promise;

        try {
            promise = this.video.play();
        } catch (error) {
            // console.error(error);
            // if (window.Bugsnag) {
            //     window.Bugsnag.notify(error);
            // }
            this.cantPlayVideo();
            return;
        }

        if (promise !== undefined) {
            promise.then(() => {
                console.log('playAndCatch.play');
                this.stopFallbackTimer();
            })
                .catch(error => {
                    // console.error(error);
                    // if (window.Bugsnag) {
                    //     window.Bugsnag.notify(error);
                    // }
                    this.cantPlayVideo();
                });
        }
    }

    cantPlayVideo() {
        this.killVideo();
        this.$el.addClass('js-cant-play-video');
        this.$video.css({
            visibility: 'hidden'
        });
        this.$el.find('img').css({
            visibility: ''
        });
        this.$el.find('.lzld').removeClass('lzld').addClass('lazyload');
    }

    onFallbackTimer() {
        console.log('video fallback timer');
        this.cantPlayVideo();
    }

    onResize() {
        this.maybeSwapSource();
    }

    onVideoPlaying() {
        this.videoIsPlaying = true;
        requestAnimationFrame(() => {
            gsap.to(this.video, { opacity: 1, duration: 0.5, ease: 'Cubic.easeIn' });
        });
    }

    onTimeUpdate() {
        if (!this.video || this.video.currentTime < 0.001) {
            return;
        }
        this.removeVideoEventListeners();
        this.stopFallbackTimer();
        this.onVideoPlaying();
    }

    onLoadStart() {
        if (this.videoIsPlaying) {
            return;
        }
        console.log('load start event');
        console.log('video started loading, increase fallback timer');
        this.startFallbackTimer(5000);
    }

};

