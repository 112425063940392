import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from "../core/Dispatch";

import {
    PROGRAMMATIC_SCROLL_END,
    PROGRAMMATIC_SCROLL_START,
    HIDE_HEADER,
    SHOW_HEADER,
    AFTER_SCROLL_LOCKED,
    AFTER_SCROLL_RELEASED
} from "../lib/events";

import { lockScroll, unlockScroll } from "../lib/helpers";

const Header = el => {

    const $el = $(el);

    const inner = $el.find('[data-inner]').get(0);
    const logo = $el.find('[data-logo]').get(0);
    const scrollElements = $el.find('[data-scroll-element]').get();
    const menuPanel = $el.find('[data-menu-panel]').get(0);
    const loginPanel = $el.find('[data-login-panel]').get(0);

    let height;
    let currentY = 0;
    let hideThreshold = 0; // The header will never be hidden if the user hasn't scrolled to at least this value. It will be dynamically set in the resize handler
    let prevScrollTop = Viewport.scrollTop;

    let focusedElementBeforeMenuOpen;
    let isHidden = false;
    let menuIsOpen = false;
    let loginIsOpen = false;
    let preventHide = false;
    let forceHide = false;

    const isSmall = () => ['m', 'mp', 'l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const handleScroll = () => {

        let scrollTop = Viewport.scrollTop;
        if (scrollTop < 0) {
            scrollTop = 0;
        }

        const direction = prevScrollTop < scrollTop ? 'down' : 'up';
        const diff = Math.abs(prevScrollTop - scrollTop);

        let y;
        let ease;
        const duration = 0.5;

        if (forceHide) {

            y = -height;
            ease = 'Power2.easeInOut';
            isHidden = true;

        } else {

            if (direction === 'up' || menuIsOpen || loginIsOpen || preventHide || scrollTop <= hideThreshold) {

                if (diff < 5) {
                    return;
                }

                y = 0;
                ease = 'Power4.easeOut';
                isHidden = false;

            } else {

                if (diff < 20) {
                    return;
                }

                // Hide it
                y = -height;
                ease = 'Power2.easeInOut';
                isHidden = true;
            }

        }

        if (y !== currentY) {

            const elements = [logo].concat(scrollElements);

            gsap.to(elements, {
                duration,
                y,
                ease
            });
        }

        currentY = y;
        prevScrollTop = scrollTop;

    };

    const closeMenu = (tween = true) => {

        if (!menuIsOpen) {
            return;
        }

        const $menuPanel = $(menuPanel);
        const overlay = $menuPanel.find('[data-menu-overlay]').get(0);
        const $content = $menuPanel.find('[data-menu-content]');
        const content = $content.get(0);
        const inner = $menuPanel.find('[data-menu-inner]').get(0);
        const elements = $(inner).find('a, p').get().filter(el => !el.hasAttribute('data-menu-toggle'));

        const timeline = gsap.timeline({
            onComplete() {
                menuIsOpen = false;
                menuPanel.hidden = true;
                $el.find('[data-menu-toggle]').attr('aria-expanded', 'false');
                gsap.set([content, inner, elements], { clearProps: 'yPercent,opacity,x' });
                Viewport.releaseTabbing(focusedElementBeforeMenuOpen || null);
                focusedElementBeforeMenuOpen = null;

                unlockScroll();
            }
        })
            .to(content, { yPercent: -100, ease: 'Quint.easeInOut', duration: 0.65 }, 0.1)
            .to(inner, { yPercent: 100, ease: 'Quint.easeInOut', duration: 0.65 }, 0.1)
            .to(elements.reverse(), {
                x: $content.width(),
                ease: 'Cubic.easeIn',
                duration: 0.5,
                stagger: 0.05
            }, 0)
            .to(overlay, { opacity: 0, duration: 0.3 }, 0.3);

        if (!tween) {
            timeline.progress(1);
        }

    };

    const openMenu = (tween = true) => {

        if (menuIsOpen) {
            return;
        }

        menuIsOpen = true;
        menuPanel.hidden = false;
        focusedElementBeforeMenuOpen = document.activeElement || null;

        $el.find('[data-menu-toggle]').attr('aria-expanded', 'true');

        const $content = $(menuPanel).find('[data-menu-content]');
        $content.find('[data-menu-toggle]').get(0).focus();

        const content = $content.get(0);
        const overlay = $(menuPanel).find('[data-menu-overlay]').get(0);
        const inner = $(menuPanel).find('[data-menu-inner]').get(0);
        const elements = $(inner).find('a, p').get().filter(el => !el.hasAttribute('data-menu-toggle'));
        const closeBtn = $(menuPanel).find('[data-menu-close]').get(0);

        const timeline = gsap.timeline({
            onComplete() {
                gsap.set([content, inner, elements], { clearProps: 'yPercent,opacity,x' });
            }
        })
            .fromTo(content, { yPercent: -100 }, { yPercent: 0, ease: 'Quint.easeInOut', duration: 0.65 }, 0)
            .fromTo(inner, { yPercent: 100 }, { yPercent: 0, ease: 'Quint.easeInOut', duration: 0.65 }, 0)
            .fromTo(elements, { x: `${$content.width()}px` }, {
                x: 0,
                ease: 'Quint.easeOut',
                duration: 0.65,
                stagger: 0.05
            },0.15)
            .fromTo(overlay, { opacity: 0 }, { opacity: 0.6, duration: 0.3 }, 0);

        if (!tween) {
            timeline.progress(1);
        }

        Viewport.lockTabbing(menuPanel, closeBtn);
        lockScroll(menuPanel);

    };

    const closeLogin = (tween = true) => {
        if (!loginIsOpen) {
            return;
        }

        loginIsOpen = false;
        $el.find('[data-login-toggle]').attr('aria-expanded', 'false');

        const $loginPanel = $(loginPanel);
        const wrap = $loginPanel.find('[data-login-wrap]').get(0);
        const content = $loginPanel.find('[data-login-content]').get(0);
        const overlay = $loginPanel.find('[data-login-overlay]').get(0);
        const closeBtn = $loginPanel.find('[data-login-close]').get(0);

        const duration = 0.75;

        const tl = gsap.timeline({
            onComplete() {
                gsap.set([wrap, content], { clearProps: 'all' });
                loginPanel.hidden = true;
                Viewport.releaseTabbing(focusedElementBeforeMenuOpen || null);
                focusedElementBeforeMenuOpen = null;

                unlockScroll();
            }
        })
            .to(wrap, { height: 0, ease: 'Quint.easeInOut', duration }, 0)
            .to(content, { y: 30, ease: 'Quint.easeInOut', duration }, 0)
            .to(content, { opacity: 0, ease: 'Quad.easeIn', duration: duration * 0.5 }, 0)
            .to(overlay, { opacity: 0, ease: 'Quint.easeInOut', duration: duration * 0.5 }, duration * 0.25)
            .to(closeBtn, { opacity: 0, duration: duration * 0.5 }, 0);

        if (!tween) {
            tl.progress(1);
        }

    };

    const openLogin = (tween = true) => {
        if (loginIsOpen) {
            return;
        }

        focusedElementBeforeMenuOpen = document.activeElement || null;

        loginIsOpen = true;
        loginPanel.hidden = false;

        $el.find('[data-login-toggle]').attr('aria-expanded', 'true');

        const wrap = $(loginPanel).find('[data-login-wrap]').get(0);
        const content = $(loginPanel).find('[data-login-content]').get(0);
        const overlay = $(loginPanel).find('[data-login-overlay]').get(0);
        const closeBtn = $(loginPanel).find('[data-login-close]').get(0);

        const duration = 0.75;

        const tl = gsap.timeline()
            .fromTo(wrap, { height: 0 }, { height: 'auto', ease: 'Quint.easeInOut', duration }, 0)
            .fromTo(content, { y: 30 }, { y: 0, ease: 'Quint.easeInOut', duration }, 0)
            .fromTo(content, { opacity: 0 }, { opacity: 1, ease: 'Quad.easeInOut', duration }, duration * 0.3)
            .fromTo(overlay, { opacity: 0 }, { opacity: 0.4, ease: 'Quint.easeInOut', duration: duration * 0.5 }, 0)
            .fromTo(closeBtn, { opacity: 0 }, { opacity: 1, duration: duration * 0.5 }, duration * 0.3);

        if (!tween) {
            tl.progress(1);
        }

        Viewport.lockTabbing(loginPanel, closeBtn);
        lockScroll(loginPanel);
    };

    const onScroll = () => {
        handleScroll();
    };

    const onResize = () => {
        height = $(inner).height();
        // The hide threshold is either the top offset for the hero's h1, or a percentage of the viewport
        const $heroHeading = $('[data-hero] h1');
        if ($heroHeading.length) {
            const heroHeadingOffset = $heroHeading.offset().top;
            hideThreshold = heroHeadingOffset - (height + 60);
        } else {
            hideThreshold = (Viewport.height * 0.25) - height;
        }
        if (!isSmall()) {
            closeMenu(false);
        }
        onScroll();
    };

    const onMenuToggleClick = e => {
        e.preventDefault();
        if (menuIsOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const onLoginToggleClick = e => {
        e.preventDefault();
        if (loginIsOpen) {
            closeLogin();
        } else {
            openLogin();
        }
    };


    const onLinkClick = () => {
        closeMenu(isSmall());
    };

    const onProgrammaticScrollStart = (key, data) => {
        // If moving down and not already hidden, prevent that
        preventHide = true;//data.direction === 'down' && !isHidden;
    };

    const onProgrammaticScrollEnd = () => {
        preventHide = false;
    };

    const onShowHeader = () => {
        forceHide = false;
    };

    const onHideHeader = () => {
        forceHide = true;
        closeMenu();
        closeLogin();
    };


    const onBodyKeyUp = e => {
        const key = e.which || e.keyCode || null;
        if (key === 27) {
            closeMenu();
            closeLogin();
        }
    };

    const onFocus = () => {
        prevScrollTop = 99999999;
        handleScroll();
    };

    const onScrollLocked = (key, { scrollBarGap }) => {
        if (!scrollBarGap) {
            return;
        }
        $el.find('[data-inner],[data-menu-inner],[data-section-nav],[data-login-panel]').css({
            paddingRight: scrollBarGap
        });
    };

    const onScrollReleased = () => {
        $el.find('[data-inner],[data-menu-inner],[data-section-nav],[data-login-panel]').css({
            paddingRight: ''
        });
    };

    const init = () => {

        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onResize);
        Viewport.on('scroll', onScroll);

        Dispatch.on(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
        Dispatch.on(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
        Dispatch.on(SHOW_HEADER, onShowHeader, true);
        Dispatch.on(HIDE_HEADER, onHideHeader, true);
        Dispatch.on(AFTER_SCROLL_LOCKED, onScrollLocked, true);
        Dispatch.on(AFTER_SCROLL_RELEASED, onScrollReleased, true);

        gsap.set([logo].concat(scrollElements), { y: 0 });

        // Init menu
        if (menuPanel) {
            const menuId = menuPanel.id;

            $el.find('[data-menu-toggle]')
                .attr({
                    role: 'button',
                    tabIndex: '0',
                    'aria-controls': menuId,
                    'aria-expanded': 'false'
                })
                .get(0)
                .removeAttribute('href');

            if (window.location.hash === `#${menuId}`) {
                openMenu(false);
                window.location.hash = '';
                if (window.history && window.history.replaceState) {
                    history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }
        }

        // Init login
        if (loginPanel) {

            const loginId = loginPanel.id;

            $el.find('[data-login-toggle]')
                .attr({
                    role: 'button',
                    tabIndex: '0',
                    'aria-controls': loginId,
                    'aria-expanded': 'false'
                })
                .get()
                .forEach(toggle => toggle.removeAttribute('href'));

            if (window.location.hash === `#${loginId}`) {
                openLogin(false);
                window.location.hash = '';
                if (window.history && window.history.replaceState) {
                    history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }

        }

        $el
            .on('click', 'a:not([data-menu-toggle]):not([data-login-toggle])', onLinkClick)
            .on('click', '[data-menu-toggle]', onMenuToggleClick)
            .on('keydown', '[data-menu-toggle]', e => {
                const key = e.which || e.keyCode || null;
                if (key === 13) {
                    e.preventDefault();
                    onMenuToggleClick(e);
                }
            })
            .on('click', '[data-login-toggle]', onLoginToggleClick)
            .on('keydown', '[data-login-toggle]', e => {
                const key = e.which || e.keyCode || null;
                if (key === 13) {
                    e.preventDefault();
                    onLoginToggleClick(e);
                }
            });

        $el.on('focusin', 'a,button,input,textarea', onFocus);

        $('body').on('keyup', onBodyKeyUp);

        onResize();

    };

    const destroy = () => {

        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onResize);
        Viewport.off('scroll', onScroll);

        Dispatch.off(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
        Dispatch.off(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
        Dispatch.off(SHOW_HEADER, onShowHeader);
        Dispatch.off(HIDE_HEADER, onHideHeader);
        Dispatch.off(AFTER_SCROLL_LOCKED, onScrollLocked);
        Dispatch.off(AFTER_SCROLL_RELEASED, onScrollReleased);

        $el.off('click keyup keydown focusin');

        $('body').off('keyup', onBodyKeyUp);

    };

    return {
        init,
        destroy
    };
};

export default Header;
