import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const $toggle = $(el.firstElementChild);
    const $target = $($toggle.get(0).nextElementSibling);

    let isExpanded = false;

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        $toggle.attr('aria-expanded', 'true');
        $target.get(0).hidden = false;
        gsap.timeline()
            .fromTo($target.get(0), 0.5, { height: 0 }, { height: 'auto', ease: 'Quad.easeInOut' }, 0)
            .fromTo($target.get(0).children, 0.3, { opacity: 0 }, { opacity: 1 }, 0.1)
            .fromTo($target.get(0).children, 0.3, { y: 20 }, { y: 0, ease: 'Quad.easeInOut' }, 0.1);
    };

    const collapse = () => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        $toggle.attr('aria-expanded', 'false');
        gsap.timeline({
            onComplete() {
                $target.get(0).hidden = true;
            }
        })
            .to($target.get(0), { height: 0, duration: 0.5, ease: 'Quad.easeInOut' }, 0)
            .to($target.get(0).children, { opacity: 0, duration: 0.3 }, 0.1)
            .to($target.get(0).children, { y: 20, duration: 0.3, ease: 'Quad.easeInOut' }, 0.1)
            .set($target.get(0), { clearProps: 'all' });
    };

    const toggle = () => {
        if (!isExpanded) {
            expand();
        } else {
            collapse();
        }
    };

    const onToggleClick = e => {
        e.preventDefault();
        toggle();
    };

    return {
        init() {
            console.log($toggle);
            if ($toggle.get(0).nodeName.toLowerCase() !== 'button') {
                $toggle
                    .attr({
                        role: 'button',
                        'aria-expanded': 'false'
                    })
                    .get(0)
                    .removeAttribute('href');
            }
            $toggle.on('click', onToggleClick);
        },
        destroy() {
            $toggle.off('click');
        }
    };

};
