import serialize from 'form-serialize';
import Dropzone from 'dropzone';
import $ from '../core/Dom';

Dropzone.autoDiscover = false;

export default (el, props) => {

    const $el = $(el);
    const submitBtn = $el.find('button[type="submit"]').get(0);
    const { maxUploadSize } = props;

    let dropzone;
    let isSubmitting;

    const buttonTemplate = $el.find('[data-button-template]').html();
    const previewTemplate = $el.find('[data-preview-template]').html();
    $el.find('[data-dropzone]').html('');

    let canSubmit = false;
    let hasFiles = false;
    let successResponse;

    const bytesToMegaBytes = (bytes, digits) => digits ? (bytes / (1024*1024)).toFixed(digits) : (bytes / (1024*1024));

    const disableOrEnableSubmit = () => {
        const { files } = dropzone;
        hasFiles = !!files.length;
        canSubmit = files.filter(file => file.status === 'queued' && !!file.accepted).length;
        if (hasFiles) {
            $el.addClass('js-has-files');
        } else {
            $el.removeClass('js-has-files');
        }
        if (canSubmit) {
            submitBtn.removeAttribute('data-disabled');
        } else {
            submitBtn.setAttribute('data-disabled', true);
        }
    };

    const onDropzoneChange = () => {
        disableOrEnableSubmit();
    };

    const createDropzone = () => {

        const url = window.location.href;
        const dropzoneDiv = $el.find('[data-dropzone]').get(0);

        dropzone = new Dropzone(dropzoneDiv, {
            url,
            params: serialize(el, { hash: true }),
            uploadMultiple: true,
            paramName: 'files',
            maxFilesize: bytesToMegaBytes(maxUploadSize),
            createImageThumbnails: false,
            maxFiles: 10,
            parallelUploads: 1,
            autoProcessQueue: false,
            dictDefaultMessage: buttonTemplate,
            dictFileTooBig: `Denne filen overskrider grensen på ${bytesToMegaBytes(maxUploadSize)} MB`,
            dictMaxFilesExceeded: 'Du har valgt for mange filer. Denne filen vil ikke bli lastet opp.',
            previewTemplate,
            success: (file, response, xhr) => {
                if (!response.success) {
                    if (response.errors) {
                        dropzone.defaultOptions.error(file, [].concat(response.errors).join('<br/>'));
                        return;
                    }
                    dropzone.defaultOptions.error(file, xhr.statusText);
                    return;
                }
                successResponse = response;
                dropzone.removeFile(file);
            }
        });

        dropzone.on('addedfiles', onDropzoneChange);

        dropzone.on('removedfile', onDropzoneChange);

        dropzone.on('queuecomplete', () => {
            dropzone.options.autoProcessQueue = false;
            isSubmitting = false;
            $el.removeClass('js-is-submitting');
            const { redirect } = successResponse || {};
            successResponse = null;
            if (!dropzone.files.length && redirect) {
                setTimeout(() => {
                    window.location.href = redirect;
                }, 150);
            } else {
                disableOrEnableSubmit();
            }
        });
    };

    const onSubmit = e => {
        e.preventDefault();
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;
        $el.addClass('js-is-submitting');
        dropzone.options.autoProcessQueue = true;
        dropzone.processQueue();
    };

    const init = () => {
        createDropzone();
        $el.on('submit', onSubmit);
    };

    const destroy = () => {
        dropzone.destroy();
        $el.off('submit');
    };

    return {
        init,
        destroy
    };

};
