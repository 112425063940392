import gsap from 'gsap';
import $ from '../core/Dom';
import {lockScroll, unlockScroll} from '../lib/helpers';
import Viewport from '../core/Viewport';

export default el => {

    const modal = el.nextElementSibling;
    const embedContainer = $(modal).find('[data-embed]').get(0);

    const embedCode = JSON.parse(embedContainer.dataset.embed);

    let isExpanded = !modal.hidden;

    const open = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        gsap.killTweensOf(modal);
        el.setAttribute('aria-expanded', 'true');
        modal.hidden = false;
        $(embedContainer).html(embedCode);
        lockScroll(modal);
        Viewport.lockTabbing(modal, $(modal).find('button[data-closebtn]').get(0));
        gsap.timeline()
          .set(modal, {pointerEvents: ''})
          .fromTo(modal, {opacity: 0}, {opacity: 1, duration: 0.5, ease: 'Cubic.easeIn'});
    };

    const close = () => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        el.setAttribute('aria-expanded', 'false');
        gsap.killTweensOf(modal);
        gsap.timeline()
          .set(modal, {pointerEvents: 'none'})
          .to(modal, {
              opacity: 0,
              duration: 0.5,
              ease: 'Cubic.easeIn',
              onComplete() {
                  modal.hidden = true;
                  $(embedContainer).html('');
                  unlockScroll();
                  Viewport.releaseTabbing(el);
              }
          });
    };

    const toggle = () => {
        if (isExpanded) {
            close();
        } else {
            open();
        }
    };

    const onClick = () => {
        toggle();
    };

    $(el).on('click', onClick);
    $(modal).on('click', 'button[data-closebtn]', close);

    return {
        destroy() {
            $(el).off('click');
            $(modal).off('click');
        }
    };

};
