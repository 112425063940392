import $ from '../core/Dom';
import Dispatch from "../core/Dispatch";
import { scrollTo } from "../lib/helpers";

import { PROGRAMMATIC_SCROLL_START, PROGRAMMATIC_SCROLL_END } from "../lib/events";

const Set = require('es6-set');

export default el => {

    const $el = $(el);
    const $links = $el.find('a');
    const targets = $($links.map(link => $(link).attr('href')).join(',')).get();

    let preventSetActive = false;

    if (!targets.length) {
        return;
    }

    let intersecting = new Set();
    let activeAnchorId;

    const setActiveAnchor = id => {
        if (id === activeAnchorId || preventSetActive) {
            return;
        }
        activeAnchorId = id;
        let $link;
        $links.each(link => {
            $link = $(link);
            if ($link.attr('href') === `#${id}`) {
                $link.addClass('is-active');
            } else {
                $link.removeClass('is-active');
            }
        });
    };

    const maybeUpdateActiveAnchor = () => {
        const activeTarget = Array.from(intersecting).pop();
        if (!activeTarget) {
            return;
        }
        setActiveAnchor(activeTarget.id);
    };

    const onLinkClick = e => {
        e.preventDefault();
        const target = $($(e.currentTarget).attr('href')).get(0);
        if (!target) {
            return;
        }
        preventSetActive = false;
        setActiveAnchor(target.id);
        scrollTo(target);
    };

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const { isIntersecting, target } = entry;
            if (isIntersecting) {
                intersecting.add(target);
            } else {
                intersecting.delete(target);
            }
        });
        maybeUpdateActiveAnchor();
    }, {
        rootMargin: '0px',
        threshold: 0
    });

    const destroyObserver = () => {
        if (!observer) {
            return;
        }
        observer.disconnect();
    };

    const onProgrammaticScrollStart = (key, data) => {
        preventSetActive = true;
    };

    const onProgrammaticScrollEnd = () => {
        preventSetActive = false;
        maybeUpdateActiveAnchor();
    };

    return {
        init() {
            targets.forEach(target => {
                observer.observe(target);
            });
            $links.on('click', onLinkClick);
            Dispatch.on(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
            Dispatch.on(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
        },
        destroy() {
            destroyObserver();
            $links.off('click');
            Dispatch.off(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
            Dispatch.off(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
        }
    };

};
