import $ from '@vaersaagod/tools/Dom';
import Viewport from "@vaersaagod/tools/Viewport";
import Dispatch from "@vaersaagod/tools/Dispatch";
import Config from "@vaersaagod/tools/Config";
import get from 'lodash/get';
import gsap from 'gsap';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import {
    PROGRAMMATIC_SCROLL_START,
    PROGRAMMATIC_SCROLL_END,
    BEFORE_SCROLL_LOCKED,
    AFTER_SCROLL_LOCKED,
    BEFORE_SCROLL_RELEASED,
    AFTER_SCROLL_RELEASED
} from "./events";

export const clamp = (number, min, max) => Math.max(min, Math.min(number, max));

export const scrollTo = (node, opts = {}) => {

    const scrollTop = $(node).offset().top;
    const direction = Viewport.scrollTop < scrollTop ? 'down' : 'up';

    Dispatch.emit(PROGRAMMATIC_SCROLL_START, {
        target: node,
        direction
    });

    const pixelsPerSecond = 1500;
    const dist = Math.abs(Viewport.scrollTop - scrollTop);
    const baseDuration = dist / pixelsPerSecond;
    const duration = clamp(baseDuration, 0.5, 2);

    requestAnimationFrame(() => {
        gsap.to($('html').get(0), {
            duration,
            scrollTop,
            ease: 'Quad.easeInOut',
            onComplete() {
                Dispatch.emit(PROGRAMMATIC_SCROLL_END);
            },
            ...opts
        });
    });

};

export const i18n = (key, vars = {}) => {
    let value = get(Config.get('i18n'), key, key);
    Object.keys(vars).forEach(name => {
        const pattern = `{${name}}`;
        value = value.replace(new RegExp(pattern, 'g'), vars[name]);
    });
    return value;
};

let scrollLockTarget;

export const unlockScroll = () => {
    if (!scrollLockTarget) {
        return;
    }
    const target = scrollLockTarget;
    Dispatch.emit(BEFORE_SCROLL_RELEASED, { target });
    enableBodyScroll(scrollLockTarget);
    scrollLockTarget = null;
    setTimeout(() => {
        Dispatch.emit(AFTER_SCROLL_RELEASED, { target });
    });
};

export const lockScroll = target => {
    unlockScroll();
    Dispatch.emit(BEFORE_SCROLL_LOCKED, { target });
    const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
    disableBodyScroll(target, {
        reserveScrollBarGap: true
    });
    scrollLockTarget = target;
    setTimeout(() => {
        Dispatch.emit(AFTER_SCROLL_LOCKED, { target, scrollBarGap });
    });
};

export const innerHeight = (function () {
    // Avoid errors when globals are undefined (CI, etc)
    // https://github.com/tylerjpeterson/ios-inner-height/pull/7
    if (typeof window === 'undefined' || typeof navigator === 'undefined') {
        return function () {
            return 0;
        };
    }

    // Non-iOS browsers return window.innerHeight per usual.
    // No caching here since browsers can be resized, and setting
    // up resize-triggered cache invalidation is not in scope.
    /* istanbul ignore if  */
    // if (!navigator.userAgent.match(/iphone|ipod|ipad/i)) {
    //     /**
    //      * Avoids conditional logic in the implementation
    //      * @return {number} - window's innerHeight measurement in pixels
    //      */
    //     return function () {
    //         return window.innerHeight;
    //     };
    // }

    // Store initial orientation
    var axis = Math.abs(window.orientation);
    // And hoist cached dimensions
    var dims = { w: 0, h: 0 };

    /**
     * Creates an element with a height of 100vh since iOS accurately
     * reports vp height (but not window.innerHeight). Then destroy it.
     */
    var createRuler = function () {
        var ruler = document.createElement('div');

        ruler.style.position = 'fixed';
        ruler.style.height = '100vh';
        ruler.style.width = 0;
        ruler.style.top = 0;

        document.documentElement.appendChild(ruler);

        // Set cache conscientious of device orientation
        dims.w = axis === 90 ? ruler.offsetHeight : window.innerWidth;
        dims.h = axis === 90 ? window.innerWidth : ruler.offsetHeight;

        // Clean up after ourselves
        document.documentElement.removeChild(ruler);
        ruler = null;
    };

    // Measure once
    createRuler();

    /**
     * Returns window's cached innerHeight measurement
     * based on viewport height and device orientation
     * @return {number} - window's innerHeight measurement in pixels
     */
    return function () {
        if (Math.abs(window.orientation) !== 90) {
            return dims.h;
        }

        return dims.w;
    };
})();


export const isLivePreview = () => document.documentElement.classList.contains('is-live-preview');
