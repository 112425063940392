import $ from '../core/Dom';

export default (el, props) => {

    const $el = $(el);

    let { seconds } = props;

    let interval = setInterval(() => {
        seconds--;
        if (seconds <= 0) {
            $el.text('00:00:00:00');
            clearInterval(interval);
            interval = null;
            return;
        }
        const dhms = {
            d: Math.floor(seconds / (3600*24)),
            h: Math.floor(seconds % (3600*24) / 3600),
            m: Math.floor(seconds % 3600 / 60),
            s: Math.floor(seconds % 60)
        };
        const value = Object.keys(dhms).reduce((carry, key) => carry.concat(dhms[key] < 10 ? `0${dhms[key]}` : dhms[key]), []);
        $el.text(value.join(':'));
    }, 1000);

    return {
        destroy() {
            if (interval) {
                clearInterval(interval);
            }
        }
    };

};
