/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const MODAL_BEFORE_OPEN = 'MODAL_BEFORE_OPEN';
export const MODAL_AFTER_OPEN = 'MODAL_AFTER_OPEN';
export const MODAL_BEFORE_CLOSE = 'MODAL_BEFORE_CLOSE';
export const MODAL_AFTER_CLOSE = 'MODAL_AFTER_CLOSE';

export const PROGRAMMATIC_SCROLL_START = 'PROGRAMMATIC_SCROLL_START';
export const PROGRAMMATIC_SCROLL_END = 'PROGRAMMATIC_SCROLL_END';

export const HIDE_HEADER = 'HIDE_HEADER';
export const SHOW_HEADER = 'SHOW_HEADER';

export const BEFORE_SCROLL_LOCKED = 'BEFORE_SCROLL_LOCKED';
export const AFTER_SCROLL_LOCKED = 'AFTER_SCROLL_LOCKED';
export const BEFORE_SCROLL_RELEASED = 'BEFORE_SCROLL_RELEASED';
export const AFTER_SCROLL_RELEASED = 'AFTER_SCROLL_RELEASED';
