import gsap from 'gsap';
import ScrambleTextPlugin from 'gsap/ScrambleTextPlugin';
import ResizeObserver from "resize-observer-polyfill";
import $ from '../core/Dom';
import { deferredCallback } from "../core/utils";
import { clamp, innerHeight } from "../lib/helpers";
import Viewport from "../core/Viewport";
import Dispatch from "../core/Dispatch";

import {
    SHOW_HEADER,
    HIDE_HEADER,
} from "../lib/events";

gsap.registerPlugin(ScrambleTextPlugin);

export default el => {

    const $el = $(el);
    const root = $el.parent('[data-modal-content]').get(0) || window;
    const teasers = $el.find('[data-task-teaser]').get();

    let resizeObserver;

    let stageW;
    let stageH;
    let timeline;
    let timelineDuration;

    let prevScrollTop;
    let isReversed;

    let displayStatus;
    let displayCounter;

    let currentStatusIndex;
    let scrollSnapStart;
    let scrollSnapEnd;
    let preventTweens = false;

    const destroyTimeline = () => {
        if (!timeline) {
            return;
        }
        timeline.pause(0);
        timeline.kill();
        timeline = null;
        const elements = $el.find('[data-teasers],[data-teasers-container],[data-task-teaser],[data-task-teaser-content],[data-task-teaser-inner],[data-task-teaser-status],[data-task-teaser-status-value],[data-task-teaser-status-label],[data-task-teaser-counter],[data-task-teaser-counter-value],[data-task-teaser-bg],[data-task-teaser-bg-inner],[data-task-teaser-bg-visuals-container],[data-task-teaser-bg-visuals-frame],[data-task-teaser-bg-visuals]').get();
        gsap.killTweensOf(elements);
        gsap.set(elements, { clearProps: 'all' });
        currentStatusIndex = null;
        $(displayStatus).parent().remove();
        displayStatus = null;
        $(displayCounter).parent().remove();
        displayCounter = null;
    };

    const updateStatusAndCounter = index => {

        index = clamp(index, 0, teasers.length - 1);

        if (index === currentStatusIndex) {
            return;
        }

        currentStatusIndex = index;

        const $teaser = $($(teasers).get(index));
        const $displayStatus = $(displayStatus);
        const $displayStatusLabel = $displayStatus.find('[data-task-teaser-status-label]');
        const newStatusLabel = $teaser.find('[data-task-teaser-status-label]').text();
        const currentStatusLabel = $displayStatusLabel.text();

        if (newStatusLabel !== currentStatusLabel) {
            gsap.timeline()
                .to($displayStatusLabel.get(0), {
                    duration: 0.3,
                    scrambleText: {
                        text: newStatusLabel || ' '
                    },
                    ease: 'Quad.easeOut'
                });
        }

        const $displayStatusValue = $displayStatus.find('[data-task-teaser-status-value]');
        const newStatusValue = $teaser.find('[data-task-teaser-status-value]').text();
        const currentStatusValue = $displayStatusValue.text();

        if (newStatusValue !== currentStatusValue) {
            gsap.timeline()
                .to($displayStatusValue.get(0), {
                    duration: 0.3,
                    scrambleText: {
                        text: newStatusValue || ' '
                    },
                    ease: 'Quad.easeOut',
                    delay: 0.1
                });
        }

        const $displayCounter = $(displayCounter);
        const $displayCounterValue = $displayCounter.find('[data-task-teaser-counter-value]');
        const newCounterValue = $teaser.find('[data-task-teaser-counter-value]').text();
        const currentCounterValue = $displayCounter.find('[data-task-teaser-counter-value]').text();

        console.log({ newCounterValue, currentCounterValue });

        if (newCounterValue !== currentCounterValue) {

            gsap.killTweensOf($displayCounterValue.get(0));

            $displayCounter.find('[data-task-teaser-counter-hint]').text($teaser.find('[data-task-teaser-counter-hint]').text());

            gsap.timeline()
                .to($displayCounterValue.get(0), {
                    duration: 0.5,
                    yPercent: -100,
                    ease: 'Quint.easeIn'
                }, 'out')
                .to($displayCounterValue.get(0), {
                    duration: 0.3,
                    opacity: 0,
                    ease: 'Cubic.easeIn'
                }, 'out+=0.2')
                .add(() => {
                    $displayCounterValue.text(newCounterValue);
                }, 'update')
                .set($displayCounterValue.get(0), { yPercent: 100 }, 'update')
                .to($displayCounterValue.get(0), {
                    duration: 0.5,
                    yPercent: 0,
                    ease: 'Quint.easeOut'
                }, 'in+=0.15')
                .to($displayCounterValue.get(0), {
                    duration: 0.3,
                    opacity: 1,
                    ease: 'Cubic.easeOut'
                }, 'in+=0.15');

        }

    };

    const createTimeline = () => {

        destroyTimeline();

        if (!teasers.length) {
            return;
        }

        timeline = gsap.timeline({
            paused: true
        });

        // Remove the top padding for the section
        const $section = $el.parent('section');
        $section.css({
            paddingTop: 0
        });

        const sectionOffset = $section.offset().top;

        const $teasers = $(teasers);
        const $firstTeaser = $($teasers.get(0));
        const offset = $firstTeaser.offset().top;

        const totalHeight = (stageH * teasers.length) + stageH; // One viewport height extra, for the intro

        const $container = $el.find('[data-teasers]');
        $container.css({
            height: `${totalHeight}px`,
            overflow: 'hidden'
        });

        // Tweak the section heading
        const sectionHeading = $el.find('[data-section-heading]').get(0);
        if (sectionHeading) {
            const tasksTopPadding = parseInt($el.css('paddingTop').replace('px', ''), 0);
            const sectionHeadingHeight = $(sectionHeading).height();
            gsap.set(sectionHeading, {
                y: Math.round((stageH * 0.5) - ((sectionHeadingHeight * 0.35) + tasksTopPadding))
            });
        }

        const $teasersContainer = $el.find('[data-teasers-container]');
        $teasersContainer.height(totalHeight);

        let prevAssetId;

        // Init display status
        const $displayStatus = $firstTeaser.find('[data-task-teaser-status]').parent().clone();
        displayStatus = $displayStatus.get(0).firstElementChild;
        $displayStatus.css({
            position: 'fixed',
            zIndex: 1
        });
        gsap.set(displayStatus, { autoAlpha: 0, y: -30 });
        $(displayStatus).find('[data-task-teaser-status-label],[data-task-teaser-status-value]').text('');
        $el.append($displayStatus);

        // Init display counter
        const $displayCounter = $firstTeaser.find('[data-task-teaser-counter]').parent().clone();
        $displayCounter.css({
            position: 'fixed',
            zIndex: 1
        });
        displayCounter = $displayCounter.get(0).firstElementChild;
        gsap.set(displayCounter, { autoAlpha: 0 });
        $el.append($displayCounter);

        // Hide status/counter
        $teasers
            .find('[data-task-teaser-status],[data-task-teaser-counter]')
            .css({ display: 'none' });

        // Hide backgrounds
        gsap.set($teasers.find('[data-task-teaser-bg]').get(), { autoAlpha: 0 });

        const width = stageW;
        const height = stageH;

        // Calculate the clip size for the intro animation
        const stageRatio = clamp(height / width, 0.7, 1.77);
        const contentWidth = $firstTeaser.find('[data-task-teaser-bg-contentsize]').get(0).getBoundingClientRect().width;
        const clipSizeWidth = contentWidth * 0.6;
        const clipSizeHeight = clipSizeWidth * stageRatio;
        const clipSizeX = Math.round((width - clipSizeWidth) * 0.5);
        const clipSizeY = Math.round((height - clipSizeHeight) * 0.5);

        // Enable/disable scroll snapping
        scrollSnapStart = (offset + stageH) - (clipSizeY + 100);
        scrollSnapEnd = (offset + totalHeight) - (stageH);

        // Show/hide status
        timeline
            .add(() => {
                gsap.timeline()
                    .to(displayStatus, {
                        duration: 0.3,
                        autoAlpha: !isReversed ? 1 : 0
                    }, 0)
                    .to(displayStatus, {
                        duration: 0.3,
                        y: !isReversed ? 0 : -30,
                        ease: !isReversed ? 'Quad.easeOut' : 'Quad.easeIn'
                    }, 0);
                Dispatch.emit(!isReversed ? HIDE_HEADER : SHOW_HEADER);
                console.log('toggle status upper');
            }, scrollSnapStart + stageH)
            .add(() => {
                gsap.timeline()
                    .to(displayStatus, {
                        duration: 0.3,
                        autoAlpha: isReversed ? 1 : 0
                    }, 0)
                    .to(displayStatus, {
                        duration: 0.3,
                        y: isReversed ? 0 : -30,
                        ease: isReversed ? 'Quad.easeOut' : 'Quad.easeIn'
                    }, 0);
                Dispatch.emit(!isReversed ? SHOW_HEADER : HIDE_HEADER);
            }, scrollSnapEnd + (stageH * 1.5));

        // Show/hide counter
        timeline
            .add(() => {
                gsap.to(displayCounter, {
                    duration: 0.3,
                    autoAlpha: !isReversed ? 1 : 0
                });
            }, scrollSnapStart + stageH)
            .add(() => {
                gsap.to(displayCounter, {
                    duration: 0.3,
                    autoAlpha: !isReversed ? 0 : 1
                });
            }, scrollSnapEnd + (stageH * 1.5));

        teasers.forEach((teaser, index) => {

            const isFirst = !index;
            const $teaser = $(teaser);

            const offset = $teaser.offset().top;

            const start = offset;

            let teaserHeight = stageH;
            if (isFirst) {
                teaserHeight += stageH;
            }

            const end = offset + teaserHeight + stageH;

            $teaser.css({
                width,
                height: teaserHeight,
                position: 'static',
                paddingTop: isFirst ? stageH : 0
            });

            // Init bg
            const assetId = $teaser.find('[data-asset]').data('asset');
            const $bg = $teaser.find('[data-task-teaser-bg]');
            $bg.css({
                width,
                height,
                position: isFirst ? 'absolute' : 'fixed'
            });

            // Tween bg (only if not "merged")
            const isMerged = assetId === prevAssetId;
            let bgStart = start + (stageH * 0.5);
            let bgEnd = end;

            if (!isMerged) {

                let isLast = true;

                const subsequentTeasers = teasers.slice(index + 1, teasers.length);
                for (let i = 0; i < subsequentTeasers.length; ++i) {
                    const subsequentTeaser = subsequentTeasers[i];
                    const subsequentAssetId = $(subsequentTeaser).find('[data-asset]').data('asset');
                    if (subsequentAssetId !== assetId) {
                        isLast = false;
                        break;
                    }
                    bgEnd += stageH;
                }

                if (isFirst) {

                    const visualsContainer = $bg.find('[data-task-teaser-bg-visuals-container]').get(0);
                    const visualsFrame = $bg.find('[data-task-teaser-bg-visuals-frame]').get(0);
                    const visuals = $bg.find('[data-task-teaser-bg-visuals]').get(0);

                    gsap.set([visualsContainer, visualsFrame, visuals], {
                        width, height
                    });

                    const bg = $bg.get(0);
                    const bgOffset = $bg.offset().top;

                    gsap.set(bg, { autoAlpha: 1 });

                    // First bg image
                    timeline
                        .add(() => {
                            if (!isReversed) {
                                gsap.set(bg, { autoAlpha: 1 });
                            } else {
                                gsap.set(bg, { autoAlpha: 0 });
                            }
                        }, bgOffset)
                        .add(() => {
                            $bg.css({
                                position: !isReversed ? 'fixed' : 'absolute'
                            });
                        }, bgOffset + stageH)
                        .fromTo(visualsFrame, stageH, {
                            width: clipSizeWidth,
                            height: clipSizeHeight,
                            x: clipSizeX,
                            y: clipSizeY
                        }, {
                            width,
                            height,
                            x: 0,
                            y: 0,
                            ease: 'Quad.easeInOut'
                            //snap: 'x,y,width,height'
                        }, bgOffset + stageH)
                        .fromTo(visuals, stageH, {
                            x: -clipSizeX,
                            y: -clipSizeY
                        }, {
                            x: 0,
                            y: 0,
                            ease: 'Quad.easeInOut'
                            //snap: 'x,top'
                        }, bgOffset + stageH);

                    // Heading
                    if (sectionHeading) {
                        timeline
                            .to(sectionHeading, stageH, {
                                opacity: 0
                            }, sectionOffset + stageH);
                    }

                } else {
                    timeline
                        .add(() => {
                            if (preventTweens) {
                                gsap.set($bg.get(0), {
                                    autoAlpha: !isReversed ? 1 : 0
                                });
                            } else {
                                gsap.to($bg.get(0), {
                                    duration: 0.5,
                                    autoAlpha: !isReversed ? 1 : 0,
                                    ease: 'Quad.easeInOut'
                                });
                            }
                        }, bgStart);
                }

                if (isLast) {
                    bgEnd -= (stageH * 0.75);
                }

                timeline
                    .add(() => {
                        if (preventTweens) {
                            gsap.set($bg.get(0), {
                                autoAlpha: !isReversed ? 0 : 1
                            });
                        } else {
                            gsap.to($bg.get(0), {
                                duration: 0.5,
                                autoAlpha: !isReversed ? 0 : 1,
                                ease: 'Quad.easeInOut'
                            });
                        }
                    }, bgEnd);

                prevAssetId = assetId;

            }

            // Update status and counter
            timeline
                .add(() => {
                    if (isReversed) {
                        updateStatusAndCounter(index - 1);
                    } else {
                        updateStatusAndCounter(index);
                    }
                }, bgStart);

        });

        timeline.add(() => {}, timeline.totalDuration() + 1);

        timelineDuration = timeline.totalDuration();

    };

    const onScroll = () => {
        if (!timeline || !timelineDuration) {
            return;
        }
        const scrollTop = root === window ? Viewport.scrollTop : root.scrollTop;
        const position = scrollTop + stageH;
        const progress = clamp(position / timelineDuration, 0, 1);
        isReversed = scrollTop < prevScrollTop;
        prevScrollTop = scrollTop;
        timeline.progress(progress);
    };

    const onResize = () => {
        const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
        const newStageW = Viewport.width - scrollBarGap;
        const newStageH = innerHeight();
        if (newStageW !== stageW || newStageH !== stageH) {
            stageW = newStageW;
            stageH = newStageH;
            createTimeline();
        }
        preventTweens = true;
        onScroll();
        preventTweens = false;
    };

    const scrollHandler = deferredCallback(onScroll);

    const init = () => {
        resizeObserver = new ResizeObserver(onResize);
        resizeObserver.observe(!root || root === window ? $('body').get(0) : root);

        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onResize);

        onResize();

        if (root !== window) {
            root.addEventListener('scroll', scrollHandler);
        } else {
            Viewport.on('scroll', onScroll);
        }
    };

    const destroy = () => {
        destroyTimeline();

        resizeObserver.disconnect();
        resizeObserver = null;

        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onResize);

        if (root !== window) {
            root.removeEventListener('scroll', scrollHandler);
        } else {
            Viewport.off('scroll', onScroll);
        }
    };

    return {
        init,
        destroy
    };

};
