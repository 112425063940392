import get from 'lodash/get';
import serialize from 'form-serialize';
import $ from '../core/Dom';
import superagent from '../core/request';
import Components from "../core/Components";
import { i18n } from '../lib/helpers';

import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);
    let { baseUrl } = props;

    const isInModal = !!$el.parent('.modal').get(0);
    if (!isInModal) {
        baseUrl = window.location.href;
    }

    let isSubmitting = false;
    let isUpdating = false;

    const selectInput = input => {
        if (!input) {
            return;
        }
        try {
            input.focus();
            input.setSelectionRange(0, input.value.length);
        } catch (error) {};
    };

    const updateContent = html => {
        isUpdating = true;
        const $html = $('<div />').append(html);
        const content = $el.find('[data-content]').get(0);
        //const contentHeight = $(content).height();
        gsap.timeline({
            onComplete() {
                isUpdating = false;
            }
        })
            .to(content, { duration: 0.3, opacity: 0 }, 'out')
            .add(() => {
                Components.destroy(content);
                // Update back button container
                const $backbutton = $el.find('[data-backbutton]');
                $backbutton.html($html.find('[data-backbutton]').html());
                // Update progress bar
                const $progressBar = $el.find('[data-progressbar]');
                $progressBar.attr('style', $html.find('[data-progressbar]').attr('style'));
                // Update content
                $(content).html($html.find('[data-content]').html());
                Components.init(content);
                selectInput($(content).find('input:not([type="hidden"]),textarea').get(0));
            }, 'update')
            //.fromTo(content, 0.5, { height: contentHeight }, { height: 'auto' }, 'in')
            .to(content, { duration: 0.3, opacity: 1 }, 'in');
    };

    const clearErrors = form => {
        const $form = $(form);
        $form.find('[data-field]').removeClass('has-errors');
        $form.find('[data-errors]').each(node => {
            $(node).html('');
            node.hidden = true;
        });
    };

    const displayErrors = (form, errors, focusToFirstError = true) => {
        const $form = $(form);
        const namespace = $form.find('input[name="namespace"]').val();
        const names = Object.keys(errors);
        names.forEach(name => {
            const error = errors[name] || null;
            if (!error) {
                return;
            }
            if (typeof(error) === 'object' && !Array.isArray(error)) {
                displayErrors(form, error, false);
                return;
            }
            const handle = ([namespace, name]).filter(value => !!value).join('-');
            const $field = $form.find(`[data-field="${handle}"]`);
            if (!$field.length) {
                return;
            }
            $field.addClass('has-errors');
            const $errors = $field.find('[data-errors]');
            if (!$errors.length) {
                return;
            }
            $errors.get(0).hidden = false;
            $errors.html([].concat(error).join('<br/'));
        });
        if (!focusToFirstError) {
            return;
        }
        try {
            selectInput($form.find('[data-field].has-errors').find('input:not([type="hidden"]),textarea').get(0));
        } catch (error) {};
    };

    const displayErrorMessage = (form, message) => {
        const $form = $(form);
        let messageDiv;
        const errorDivs = $form.find('[data-errors]').get();
        for (let i = 0; i < errorDivs.length; ++i) {
            if (!$(errorDivs[i]).parent('[data-field]').length) {
                messageDiv = errorDivs[i];
                break;
            }
        }
        if (!messageDiv) {
            return;
        }
        $(messageDiv).text(message).get(0).hidden = false;
    };

    const onFormSubmit = e => {

        e.preventDefault();

        if (isSubmitting || isUpdating) {
            return;
        }

        isSubmitting = true;

        const form = e.triggerTarget;

        const $form = $(form);
        $form.addClass('js-is-submitting');

        const data = serialize($form.get(0), { hash: false });

        superagent
            .post(window.location.href)
            .send(data)
            .set('Accept', 'application/json')
            .then(({ status, body }) => {
                clearErrors(form);
                if (status !== 200) {
                    throw new Error(body.errorMessage || body.message || null);
                }
                const { error, errors, errorMessage: message } = body;
                if (errors && Object.values(errors).length) {
                    displayErrors(form, errors);
                    isSubmitting = false;
                    $form.removeClass('js-is-submitting');
                } else if (message || error) {
                    displayErrorMessage(form, message || error);
                    isSubmitting = false;
                    $form.removeClass('js-is-submitting');
                } else {
                    // Get fresh HTML
                    superagent
                        .get(baseUrl)
                        .then(({ status, text: html }) => {
                            if (status !== 200 || !html) {
                                throw new Error();
                            }
                            isSubmitting = false;
                            $form.removeClass('js-is-submitting');
                            updateContent(html);
                        })
                        .catch(error => {
                            const statusCode = get(error, 'response.statusCode');
                            if (statusCode === 302) {
                                const redirectUrl = get(error, 'response.headers.x-redirect');
                                if (redirectUrl) {
                                    window.location.href = redirectUrl;
                                    return;
                                }
                            }
                            isSubmitting = false;
                            $form.removeClass('js-is-submitting');
                            console.error(error);
                        });
                    // If the form is named, track it to GTM
                    const formName = $form.find('input[type="hidden"][name="formName"]').val();
                    if (formName) {
                        const event = {
                            'event': 'form_submitted',
                            'form': formName,
                            'url': window.location.href,
                        };
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push(event);
                    }
                }
            })
            .catch(error => {
                const { errorCode } = error.response || {};
                if (errorCode === 302) {
                    const redirectUrl = error.response.headers['x-redirect'] || null;
                    if (redirectUrl) {
                        window.location.href = redirectUrl;
                    }
                }
                const message = get(error, 'response.body.errorMessage') || get(error, 'response.body.message') || i18n('Something went wrong, please try again later.');
                displayErrorMessage(message);
                isSubmitting = false;
                $form.removeClass('js-is-submitting');
            });
    };

    return {
        init() {
            $el.on('submit', 'form', onFormSubmit);
            selectInput($el.find('[data-content]').find('input:not([type="hidden"]),textarea').get(0));
        },
        destroy() {
            $el.off('submit');
        }
    };

};
