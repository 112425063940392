import gsap from 'gsap';
import $ from '../core/Dom';

export default (el, props) => {

    const thumb = $(el).find('[data-thumb]').get(0);
    const embed = $(el).find('[data-embed]').get(0);

    const { embedCode } = props;

    let hasClicked = false;

    const onThumbClick = e => {
        e.preventDefault();
        if (hasClicked) {
            return;
        }
        hasClicked = true;
        $(embed).html(embedCode);
        requestAnimationFrame(() => {
            gsap.timeline({
                onComplete() {
                    thumb.hidden = true;
                }
            })
                .to(thumb, { duration: 0.5, opacity: 0 }, 0);
        });
    };

    $(thumb).on('click', onThumbClick);

    return {
        destroy() {
            $(thumb).off('click');
        }
    };

};
