import $ from '../core/Dom';
import Modal from "../lib/Modal";

export default (el, props) => {

    const { overlayOpacity = 1 } = props || {};

    const onClick = e => {
        e.preventDefault();
        const href = $(el).attr('href');
        Modal(href, props);
    };

    $(el).on('click', onClick);

    return {
        destroy() {
            $(el).off('click');
        }
    };

};
