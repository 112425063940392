import gsap from 'gsap';
import $ from '../core/Dom';

export default (el, props) => {

    const $el = $(el);
    const { cookieName, cookieDuration } = props;

    let hasConsented = false;

    const consent = () => {
        if (hasConsented) {
            return;
        }
        hasConsented = true;
        window.vrsg.setCookie(cookieName, '1', cookieDuration);
        gsap.to(el, 0.5, {
            yPercent: 100,
            onComplete: () => {
                $el.remove();
            }
        });
    };

    const init = () => {
        $el.on('click', '[data-cookieconsentbtn]', consent);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
