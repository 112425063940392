import WagerfieldParallax from 'parallax-js'
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

export default el => {

    let parallax = new WagerfieldParallax(el);

    return {
        destroy() {
            parallax.destroy();
        }
    };

};
