import gsap from 'gsap';
import Rellax from 'rellax';
import lazySizes from './lib/lazysizes';
import Viewport from './core/Viewport';
import Components from './core/Components';
import $ from './core/Dom';
import {isLivePreview, scrollTo} from "./lib/helpers";
import Revealer from './lib/Revealer';
import Parallaxer from "./lib/Parallaxer";

gsap.defaults({ overwrite: 'auto' });

/*
*   Bootstrap app
*
 */
const init = () => {

    Viewport.init();

    Parallaxer({
        root: window,
        panelSelector: '#main [data-parallax-panel]'
    }).init();

    lazySizes.init();

    Components.init();

    Viewport.initTabbing();

    // Init anchor links
    $('body').on('click', 'a[data-anchorlink]', e => {
        const id = $(e.triggerTarget).attr('href');
        if (!id || !id.startsWith('#')) {
            return true;
        }
        const target = $(id).get(0);
        if (!target) {
            return true;
        }
        scrollTo(target);
        e.preventDefault();
        return false;
    });

    if (!isLivePreview()) {
        setTimeout(() => {
            Revealer().init();
        }, 0);
    }

    if ($('[data-rellax]').length) {
        Rellax('[data-rellax]', {
            center: true
        });
    }

    setTimeout(() => {
        $('html').addClass('js-init');
    }, 0);

};

require('doc-ready')(init);
