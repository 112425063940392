import ResizeObserver from 'resize-observer-polyfill';
import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Components from "../core/Components";
import { deferredCallback } from '../core/utils';
import { clamp, innerHeight } from "../lib/helpers";

export default ({ root = window, panelSelector = '[data-parallax-panel]' }) => {

    const $container = root === window ? $('body') : $(root);

    let resizeObserver;
    let panels;
    let numPanels;
    let stageW;
    let stageH;
    let timeline;
    let timelineDuration;

    const destroyTimeline = () => {
        if (!timeline) {
            return;
        }
        timeline.kill();
        timeline = null;
        (panels || []).forEach(panel => {
            const $panel = $(panel);
            $panel.css({
                paddingTop: '',
                paddingBottom: '',
                position: ''
            });
            const content = $panel.find('[data-parallax-content]').get(0);
            const visualsContainer = $panel.find('[data-parallax-visuals-container]').get(0);
            $(visualsContainer).css({
                width: '',
                height: '',
                position: ''
            });
            const visualsFrame = $panel.find('[data-parallax-visuals-frame]').get(0);
            const visuals = $panel.find('[data-parallax-visuals]').get(0);
            gsap.set([panel, content, visualsContainer, visualsFrame, visuals], { clearProps: 'all' });
        });
        const sectionHeadings = $container.find('[data-section-heading]').get();
        if (sectionHeadings.length) {
            gsap.set(sectionHeadings, { clearProps: 'all' });
        }
    };

    const createTimeline = () => {

        destroyTimeline();

        timeline = gsap.timeline({
            paused: true
        });

        panels = $container.find(panelSelector).get();
        numPanels = panels.length;

        if (!numPanels) {
            return;
        }

        const containerOffset = $container.offset().top;
        const visualsFadeDuration = clamp(1500, 0, stageH * 0.9); // The duration for the fades
        const visualsFadeEasingMethod = 'Quad.easeInOut'; // The easing function to use for the fades

        let previousWasOutro = false;

        panels.forEach((panel, index) => {

            const { width: panelWidth, height: panelHeight } = panel.getBoundingClientRect();

            const $panel = $(panel);
            let panelTop = Math.floor($panel.offset().top - containerOffset);
            const panelBottom = panelTop + panelHeight;

            // Create the timeline
            let panelTimeline = gsap.timeline();
            let panelStart = panelTop;

            // Things we need to keep track of
            let doIntroAnimation = false;
            let doOutroAnimation = false;
            let panelDuration = panelHeight;
            let extraPaddingTop = 0;
            let extraPaddingBottom = 0;

            // Check if this panel is immediately following another panel with the same image/video
            // If it is, *this* panel has been "merged" into the preceding panel's timeline, which means we can "skip" this one (i.e. not do most of the timeline stuff)
            const assetId = $panel.data('asset');
            const $prevPanel = index ? $(panels[index - 1]) : null;
            const mergePanel = !!($prevPanel && $prevPanel.length && (panelTop - (($prevPanel.offset().top - containerOffset) + $prevPanel.height())) < 1 && assetId === $prevPanel.data('asset'));

            const visualsContainer = $panel.find('[data-parallax-visuals-container]').get(0);
            gsap.set(visualsContainer, { autoAlpha: 0 });

            // Set visuals to fixed
            $panel.css({
                position: 'static'
            });

            $(visualsContainer).css({
                width: `${panelWidth}px`,
                height: `${panelHeight}px`,
                position: 'fixed'
            });

            if (!mergePanel) {

                // Get all (immediately adjacent) subsequent panels – we'll need them for a couple of things
                let subsequentPanels = panels.slice(index + 1, panels.length);
                let subsequentPanelsBottom = panelBottom;
                for (let i = 0; i < subsequentPanels.length; ++i) {
                    const $subsequentPanel = $(subsequentPanels[i]);
                    const subsequentPanelTop = $subsequentPanel.offset().top - containerOffset;
                    if (subsequentPanelTop - subsequentPanelsBottom > 1) {
                        subsequentPanels = subsequentPanels.slice(0, i);
                        break;
                    }
                    subsequentPanelsBottom = subsequentPanelTop + $subsequentPanel.height();
                }

                // Figure out if we should show the *intro* animation for this panel
                // We'll show the intro if the previous panel was an outro, or if it's first panel (and the offset top is higher than 0)
                doIntroAnimation = previousWasOutro || (!index && !!panelTop);
                if (doIntroAnimation) {
                    extraPaddingTop = stageH;
                    $panel.css({
                        paddingTop: `${extraPaddingTop}px`
                    });
                    panelDuration += extraPaddingTop;
                }

                // Figure out if we should show the *outro* animation for this panel, i.e. figure out if this is the *last* panel in the set
                // It's the last panel if its... actually the last panel, or if all subsequent panels use the same asset ID
                doOutroAnimation = index === numPanels - 1 || !(subsequentPanels.filter(panel => $(panel).data('asset') !== assetId)).length;
                if (doOutroAnimation) {
                    extraPaddingBottom = 100;//Math.round(stageH * 0.5);
                    // Get the last panel in the set
                    const panelsInSet = [panel];
                    for (let i = 0; subsequentPanels.length; ++i) {
                        if ($(subsequentPanels[i]).data('asset') !== assetId) {
                            break;
                        }
                        panelsInSet.push(subsequentPanels[i]);
                    }
                    $(panelsInSet[panelsInSet.length - 1]).css({
                        paddingBottom: `${extraPaddingBottom}px`
                    });
                    if (panelsInSet.length > 1) {
                        panelDuration -= extraPaddingBottom;
                    }
                }

                // Loop over subsequent panels – if any panels immediately following this one use the same image/video, they'll be skipped (we'll just increase the duration for *this* panel)
                let $subsequentPanel;
                for (let i = 0; i < subsequentPanels.length; ++i) {
                    $subsequentPanel = $(subsequentPanels[i]);
                    if ($subsequentPanel.data('asset') === assetId) {
                        // Add this panel's image to "our" panel's total duration
                        panelDuration += Math.floor($subsequentPanel.height());
                    } else {
                        break;
                    }
                }

                // We're not going to tween the actual panel, only the image/video
                // Get all the DOM elements we need
                const visualsFrame = $panel.find('[data-parallax-visuals-frame]').get(0);
                const visuals = $panel.find('[data-parallax-visuals]').get(0);

                const contentWidth = $panel.find('[data-parallax-contentsize]').get(0).getBoundingClientRect().width;
                let stageRatio = stageH / stageW;
                if (stageW >= stageH && stageRatio < 0.7) {
                    stageRatio = 0.7;
                }

                gsap.set([visualsFrame, visuals], {
                    width: panelWidth,
                    height: panelHeight
                });

                const clipSizeWidth = contentWidth * 0.6;
                const clipSizeHeight = clipSizeWidth * stageRatio;
                const clipSizeX = Math.round((stageW - clipSizeWidth) * 0.5);
                const clipSizeY = Math.round((stageH - clipSizeHeight) * 0.5);

                /**
                 * Intro animation
                 */
                if (doIntroAnimation) {

                    // If there's a section heading immediately preceding this panel, make it part of the intro
                    const sectionHeading = $container.find('[data-section-heading]').get().filter(sectionHeading => Math.abs((($(sectionHeading).offset().top - $container.offset().top) + $(sectionHeading).height()) - panelTop) <= 1)[0] || null;

                    if (sectionHeading) {
                        const sectionHeadingHeight = $(sectionHeading).height();
                        gsap.set(sectionHeading, {
                            y: Math.round((stageH * 0.5) + (sectionHeadingHeight * 0.5))
                        });
                        panelTimeline
                            .to(sectionHeading, {
                                opacity: 0,
                                duration: stageH
                            }, stageH);
                    }

                    gsap.set(visualsContainer, {
                        css: {
                            position: 'absolute',
                            top: panelTop
                        }
                    });

                    panelTimeline
                        .set(visualsContainer, {
                            css: {
                                position: 'fixed',
                                top: 0
                            }
                        }, stageH)
                        .set(visualsContainer, {
                            autoAlpha: 1
                        }, 0)
                        .fromTo(visualsFrame, {
                            width: clipSizeWidth,
                            height: clipSizeHeight,
                            left: clipSizeX,
                            top: clipSizeY
                        }, {
                            width: panelWidth,
                            height: panelHeight,
                            left: 0,
                            top: 0,
                            ease: 'Quad.easeInOut',
                            duration: stageH
                            //snap: 'left,top,width,height',
                        }, stageH)
                        .fromTo(visuals, {
                            left: -clipSizeX,
                            top: -clipSizeY
                        }, {
                            left: 0,
                            top: 0,
                            ease: 'Quad.easeInOut',
                            duration: stageH
                            //snap: 'left,top',
                        }, stageH);

                } else {

                    // No intro – simply set the panel to fixed, and fade in
                    panelTimeline
                        .fromTo(visualsContainer, {
                            autoAlpha: 0
                        }, {
                            autoAlpha: 1,
                            duration: visualsFadeDuration,
                            ease: visualsFadeEasingMethod
                        }, 0);
                }

                /**
                 * Outro animation - TODO pause inline video when the panel is completely hidden?
                 *
                 */
                if (doOutroAnimation) {

                    panelTimeline
                        .to(visualsContainer, {
                            autoAlpha: 0,
                            ease: visualsFadeEasingMethod,
                            duration: stageH * 0.75
                        }, panelDuration);

                } else {

                    // No outro animation – simply hide image
                    panelTimeline
                        .set(visualsContainer, {
                            autoAlpha: 0
                        }, panelDuration + (visualsFadeDuration + 1));

                }

                // Maybe init inline video
                const videoSources = $panel.data('videosource');
                if (videoSources) {
                    panel.removeAttribute('data-videosource');
                    $panel.attr({
                        'data-component': 'InlineVideo',
                        'data-component-props': JSON.stringify({
                            ...videoSources
                            //forcePlay: true
                        })
                    });
                    Components.init(panel);
                } else if (panel.hasAttribute('data-hasvimeoembed')) {
                    panel.removeAttribute('data-hasvimeoembed');
                    $panel.attr({
                        'data-component': 'InlineVimeoEmbed'
                    });
                    Components.init(panel);
                }

                previousWasOutro = doOutroAnimation;

            }

            timeline.add(panelTimeline, `panel+=${panelStart}`);

        });

        timelineDuration = timeline.totalDuration();

    };

    const handleScroll = () => {
        if (!timeline || !timelineDuration) {
            return;
        }
        let scrollTop;
        if (!root || root === window) {
            scrollTop = Viewport.scrollTop;
        } else {
            scrollTop = root.scrollTop;
        }
        const position = Math.round(scrollTop + stageH);
        const progress = clamp(position / timelineDuration, 0, 1);
        timeline.progress(progress);
    };

    const scrollHandler = deferredCallback(handleScroll);

    const onResize = () => {

        const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
        const newStageW = Viewport.width - scrollBarGap;
        const newStageH = innerHeight();

        if (newStageW !== stageW || newStageH !== stageH) {
            stageW = newStageW;
            stageH = newStageH;
            createTimeline();
        }

        handleScroll();
    };

    const init = () => {

        resizeObserver = new ResizeObserver(onResize);
        resizeObserver.observe(!root || root === window ? $('body').get(0) : root);

        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onResize);

        onResize();

        if (root !== window) {
            root.addEventListener('scroll', scrollHandler);
        } else {
            Viewport.on('scroll', handleScroll);
        }
    };

    const destroy = () => {

        destroyTimeline();

        resizeObserver.disconnect();
        resizeObserver = null;

        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onResize);

        if (root !== window) {
            root.removeEventListener('scroll', scrollHandler);
        } else {
            Viewport.off('scroll', handleScroll);
        }
    };

    return {
        init,
        destroy
    };

};
