import $ from '../core/Dom';
import Dispatch from "../core/Dispatch";
import superagent from '../core/request';

import Components from "../core/Components";
import Revealer from './Revealer';
import Parallaxer from "./Parallaxer";

import {
    MODAL_BEFORE_OPEN,
    MODAL_AFTER_OPEN,
    MODAL_BEFORE_CLOSE,
    MODAL_AFTER_CLOSE
} from "./events";
import { lockScroll, unlockScroll } from "./helpers";

import gsap from 'gsap';
import get from "lodash/get";
import Viewport from "../core/Viewport";

let modalIsOpen = false;

export default (url, options) => {

    // Get the modal mount
    const $modal = $('#modal');
    const modal = $modal.get(0);
    const top = $modal.find('[data-modal-top]');
    const content = $modal.find('[data-modal-content]').get(0);
    const bg = $modal.find('[data-modal-bg]').get(0);

    let focusedElementBeforeOpen;
    let destroyHandler;

    let revealer;
    let parallaxer;

    const { opacity = 1, modalTheme: theme = null } = options || {};
    gsap.set(bg, { opacity });

    // Remove theme class
    for (let i = modal.classList.length - 1; i >= 0; i--) {
        const className = modal.classList[i];
        if (className.startsWith('theme-')) {
            modal.classList.remove(className);
        }
    }

    // Maybe add new theme
    if (theme) {
        $modal.addClass(`theme-${theme}`);
    }

    const closeModal = () => {
        Dispatch.emit(MODAL_BEFORE_CLOSE);
        gsap.timeline({
            onComplete() {
                modal.hidden = true;
                destroyHandler();
                Viewport.releaseTabbing(focusedElementBeforeOpen || null);
                focusedElementBeforeOpen = null;
                unlockScroll();
                Dispatch.emit(MODAL_AFTER_CLOSE);
            }
        })
            .to(modal, {
                opacity: 0,
                duration: 0.3
            }, 0);
    };

    const openModal = () => {
        Dispatch.emit(MODAL_BEFORE_OPEN);
        modal.hidden = false;
        modalIsOpen = true;
        gsap.timeline({
            delay: 0.3,
            onComplete() {
                Dispatch.emit(MODAL_AFTER_OPEN);
            }
        })
            .fromTo(modal, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.3
            }, 0);
        focusedElementBeforeOpen = document.activeElement || null;
        const closeBtn = $(modal).find('[data-modal-close]').get(0);
        Viewport.lockTabbing(modal, closeBtn);
        const scrollGap = window.innerWidth - document.documentElement.clientWidth;
        lockScroll(content);
        if (scrollGap) {
            setTimeout(() => {
                $(top).css({
                    paddingRight: scrollGap
                });
            });
        }
    };

    const initContent = html => {
        const tl = gsap.timeline({ paused: true });
        if (modalIsOpen) {
            tl
                .to(content, { opacity: 0, duration: 0.3 })
                .add(() => {
                    $(content).html('');
                    Components.destroy(content);
                    if (revealer) {
                        revealer.destroy();
                    }
                    if (parallaxer) {
                        parallaxer.destroy();
                    }
                });
        }
        tl
            .add(() => {
                content.scrollTop = 0;
                $(content).html(html);
                parallaxer = Parallaxer({
                    root: content
                });
                parallaxer.init();
                Components.init(content);
                revealer = Revealer(content);
                revealer.init();
            })
            .set(content, { opacity: 1 });
        requestAnimationFrame(() => {
            tl.play();
        });
    };

    const loadContent = () => {
        superagent
            .get(url)
            .then(({ status, text: html }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                initContent(html);
            })
            .catch(error => {
                const statusCode = get(error, 'response.statusCode');
                if (statusCode === 302) {
                    const redirectUrl = get(error, 'response.headers.x-redirect');
                    if (redirectUrl) {
                        window.location.href = redirectUrl;
                        return;
                    }
                }
                console.error(error);
            });
    };

    const onBodyKeyUp = e => {
        const key = e.which || e.keyCode || null;
        if (key === 27) {
            closeModal();
        }
    };

    const init = () => {
        $('body').on('keyup', onBodyKeyUp);

        $(modal).on('click', '[data-modal-close]', e => {
            e.preventDefault();
            console.log('modal close click');
            closeModal();
        });

        openModal();
        loadContent();
    };

    destroyHandler = () => {
        modalIsOpen = false;
        $('body').off('keyup', onBodyKeyUp);
        $(modal).off('click');
        Components.destroy(content);
        if (revealer) {
            revealer.destroy();
        }
        if (parallaxer) {
            parallaxer.destroy();
        }
        $(content).html('');
        gsap.set([modal, content], { clearProps: 'all' });
        focusedElementBeforeOpen = null;
    };

    init();

};
