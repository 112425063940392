import gsap from 'gsap';
import ResizeObserver from 'resize-observer-polyfill';
import $ from '../core/Dom';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

export default (el, props = {}) => {

    let splitText;
    let timeline;

    const doSplitText = () => {
        const time = timeline ? timeline.time() : 0;
        if (timeline) {
            timeline.kill();
        }
        if (splitText) {
            splitText.revert();
        }
        splitText = new SplitText(el, { type: 'lines' });
        let lines = [];
        Array.from(el.children).forEach(node => {
            const $lineWrap = $(node);
            $lineWrap.css({
                overflow: 'hidden',
                paddingBottom: '20px',
                marginBottom: '-20px'
            });
            new SplitText(node, { type: 'lines' });
            lines = lines.concat(Array.from(node.children));
        });
        const lineHeight = lines[0].getBoundingClientRect().height;
        let delay = 0;
        if (props.delay) {
            delay = props.delay;
            delete props.delay;
        }
        timeline = gsap.timeline({
            ...props
        })
            .fromTo(lines, {
                y: lineHeight + 60
            }, {
                y: 0,
                ease: 'Quint.easeOut',
                duration: 1,
                stagger: 0.1
            }, 0.01 + delay)
            .fromTo(lines, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.5,
                stagger: 0.1
            }, 0.01 + delay);
        timeline.pause(time);
    };

    new ResizeObserver(doSplitText);

    doSplitText();

    return {
        pause(time = null) {
            if (!timeline) {
                return;
            }
            timeline.pause(time);
        },
        play(from = null) {
            if (!timeline) {
                return;
            }
            timeline.play(from);
        },
        progress() {
            if (!timeline) {
                return 0;
            }
            return timeline.totalProgress();
        },
        duration() {
            if (!timeline) {
                return 0;
            }
            return timeline.totalDuration();
        },
        kill() {
            if (!timeline) {
                return;
            }
            timeline.kill();
        },
        isActive() {
            if (!timeline) {
                return false;
            }
            return timeline.isActive();
        }
    };

};
