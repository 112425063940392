import $ from '../core/Dom';
import superagent from '../core/request';

import { i18n } from '../lib/helpers';
import get from 'lodash/get';

export default (form, props) => {

    const $form = $(form);
    const { resetAfterSubmit = true, redirectOnSuccess = true, redirectOnSuccessTimeout = 1000, trackToGtm = false } = props || {};

    let isSubmitting = false;

    const clearErrors = () => {
        $form.find('[data-field]').removeClass('has-errors');
        $form.find('[data-errors]').each(node => {
            $(node).html('');
            node.hidden = true;
        });
    };

    const hideReceipt = () => {
        const receipt = $form.find('[data-receipt]').get(0);
        if (!receipt) {
            return;
        }
        receipt.hidden = true;
    };

    const displayReceipt = () => {
        if (resetAfterSubmit) {
            $form.find('input:not([type="hidden"]):not([type="checkbox"]):not([type="radio"]),textarea').each(input => input.value = '');
            $form.find('input[type="checkbox"],input[type="radio"]').each(input => input.checked = false);
        }
        const receipt = $form.find('[data-receipt]').get(0);
        if (receipt) {
            receipt.hidden = false;
        }
        if (trackToGtm) {
            const event = {
                'event': 'form_submitted',
                'form': $form.find('input[type="hidden"][name="formName"]').val(),
                'url': window.location.href,
            };
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(event);
            console.info('tracked to gtm', { event });
        }
    };

    const displayErrors = (errors, focusToFirstError = true) => {
        console.log({ errors });
        const namespace = $form.find('input[name="namespace"]').val();
        const names = Object.keys(errors);
        names.forEach(name => {
            const error = errors[name] || null;
            if (!error) {
                return;
            }
            if (typeof(error) === 'object' && !Array.isArray(error)) {
                displayErrors(error, false);
                return;
            }
            const handle = ([namespace, name]).filter(value => !!value).join('-');
            const $field = $form.find(`[data-field="${handle}"]`);
            if (!$field.length) {
                return;
            }
            $field.addClass('has-errors');
            const $errors = $field.find('[data-errors]');
            if (!$errors.length) {
                return;
            }
            $errors.get(0).hidden = false;
            $errors.html([].concat(error).join('<br/'));
        });
        if (!focusToFirstError) {
            return;
        }
        try {
            $form.find('[data-field].has-errors').find('input:not([type="hidden"]),textarea').get(0).focus();
        } catch (error) {};
    };

    const displayErrorMessage = message => {
        console.info('display error message', { message });
        let messageDiv;
        const errorDivs = $form.find('[data-errors]').get();
        for (let i = 0; i < errorDivs.length; ++i) {
            if (!$(errorDivs[i]).parent('[data-field]').length) {
                messageDiv = errorDivs[i];
                break;
            }
        }
        if (!messageDiv) {
            return;
        }
        $(messageDiv).text(message).get(0).hidden = false;
    };

    const submit = () => {

        if (isSubmitting) {
            return;
        }

        isSubmitting = true;
        $form.addClass('js-is-submitting');
        hideReceipt();

        const request = superagent
            .post(window.location.href)
            .set('Accept', 'application/json')
            .send(new FormData(form))
            .then(({ status, body }) => {
                clearErrors();
                if (status !== 200) {
                    throw new Error(body.errorMessage || body.message || null);
                }
                const { error, errors, errorMessage: message } = body;
                if (errors && Object.values(errors).length) {
                    displayErrors(errors);
                    $form.removeClass('js-is-submitting');
                } else if (message || error) {
                    displayErrorMessage(message || error);
                    $form.removeClass('js-is-submitting');
                } else {
                    displayReceipt();
                    if (redirectOnSuccess) {
                        let redirectUrl;
                        if (typeof redirectOnSuccess === 'string') {
                            redirectUrl = redirectOnSuccess;
                        } else if (body.redirect) {
                            redirectUrl = body.redirect;
                        }
                        if (!redirectUrl) {
                            $form.removeClass('js-is-submitting');
                            return;
                        }
                        if (redirectUrl.slice(0, 4) !== 'http') {
                            redirectUrl = `/${redirectUrl}`;
                        }
                        setTimeout(() => {
                            window.location.href = redirectUrl;
                        }, redirectOnSuccessTimeout);
                    }
                }
            })
            .catch(error => {
                const message = get(error, 'response.body.errorMessage') || get(error, 'response.body.message') || i18n('Something went wrong, please try again later.');
                displayErrorMessage(message);
                $form.removeClass('js-is-submitting');
            })
            .then(() => {
                isSubmitting = false;
                if (!redirectOnSuccess) {
                    $form.removeClass('js-is-submitting');
                }
            });
    };

    $form.on('submit', e => {
        e.preventDefault();
        submit();
    });

    return {
        destroy() {
            $form.off('submit');
        }
    };

};
