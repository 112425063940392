import gsap from 'gsap';
import throttle from 'lodash/throttle';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import growTextEffect from "./growTextEffect";

const DEFAULT_STAGGER = 150;

export default (root = window) => {

    const tweens = new WeakMap();
    const timeouts = new WeakMap();

    let nodes = [];
    let currentScrollTop = -1;
    let direction = 'down';
    let observer;
    let intersecting = [];

    const getTween = el => {

        let tween = tweens.get(el);

        if (tween) {
            return tween;
        }

        if (el.dataset.revealed) {
            return null;
        }

        const {
            reveal: type,
            revealDelay: delay = 0
        } = el.dataset;

        if (type === 'y' && el.children.length) {

            const y = parseInt(el.dataset.revealY || 50, 10);
            tween = gsap.timeline({ paused: true, delay })
                .fromTo(el,  { opacity: 0 }, { opacity: 1, ease: 'Quad.easeIn', duration: 0.5 }, 0)
                .fromTo(el.children,  { y }, { y: 0, ease: 'Quint.easeOut', duration: 1 }, 0)
                .set(el, { clearProps: 'opacity' })
                .set(el.children, { clearProps: 'transform' });

        } else if (type === 'grow') {

            tween = growTextEffect(el, { paused: true, delay });

        } else {

            // Default is fade
            tween = gsap.timeline({ paused: true, delay })
                .fromTo(el.children.length ? el.children : el, { opacity: 0 }, { opacity: 1, ease: 'Quad.easeIn', duration: 0.5 }, 0)
                .set(el.children.length ? el.children : el, { clearProps: 'opacity' });
        }

        tweens.set(el, tween);

        el.setAttribute('data-revealed', true);

        return tween;

    };

    const onScroll = () => {
        const scrollTop = (root === window ? Viewport : root).scrollTop;
        direction = scrollTop >= currentScrollTop ? 'down' : 'up';
        currentScrollTop = scrollTop;
    };

    const trackNodes = () => {
        $(root === window ? 'body' : root).find('[data-reveal]:not([data-revealed])')
            .each(node => {
                if (!node.offsetParent) {
                    return;
                }
                observer.observe(node);
                nodes.push(node);
            });
    };

    const scrollHandler = throttle(onScroll, 10);

    const onObserve = entries => {

        entries.forEach(entry => {

            const {
                target,
                isIntersecting
            } = entry;

            // Get tween
            const tween = getTween(target);

            if (!tween) {
                return;
            }

            let timeout = timeouts.get(target);
            if (timeout) {
                clearTimeout(timeout);
                timeouts.delete(target);
                timeout = null;
            }

            if (isIntersecting) {
                intersecting.push(target);
                // Easiest way I could think of to sort the array of intersecting elements according to their chronological position in the DOM (which is a good idea)
                intersecting = nodes.filter(node => intersecting.indexOf(node) > -1);
            } else {
                intersecting = intersecting.filter(node => node !== target);
            }

            const { top } = target.getBoundingClientRect();

            if (!isIntersecting && direction === 'up' && top >= Viewport.height) {
                // Reset the effect
                tween.pause(0, false);
                return;
            }

            // Calculate base stagger
            let stagger = target.dataset.revealStagger;
            if (stagger === undefined) {
                stagger = DEFAULT_STAGGER;
            }
            stagger = parseInt(stagger, 10);

            if (!isIntersecting && top < 0) {

                tween.pause(tween.duration(), false);

            } else if (isIntersecting && (!tween.progress() || !tween.isActive())) {

                stagger *= Math.max(0, intersecting.filter(node => getTween(node) && getTween(node)
                    .progress() <= 0.05)
                    .indexOf(target));

                if (!stagger) {
                    tween.play();
                    return;
                }

                timeout = setTimeout(() => {
                    clearTimeout(timeout);
                    timeouts.delete(target);
                    tween.play();
                }, stagger);
            }

        });

    };

    const createObserver = () => {

        observer = new IntersectionObserver(onObserve, {
            root: root === window ? document : root,
            threshold: [0, 0.5, 1],
            rootMargin: '0%',// 0% 10% 0%'
        });

    };

    let updateHandler;

    const init = () => {

        onScroll();

        createObserver();

        trackNodes();

        root.addEventListener('scroll', scrollHandler);

        Viewport.on('resize', updateHandler);

    };

    const update = () => {

        if (!observer) {
            return;
        }

        // Unobserve nodes that are no longer in the DOM
        nodes = nodes.reduce((carry, node) => {
            if (node.closest('html')) {
                return carry.concat(node);
            }
            observer.unobserve(node);
            const tween = tweens.get(node);
            if (tween) {
                tweens.delete(node);
                tween.kill();
            }
            const timeout = timeouts.get(node);
            if (timeout) {
                timeouts.delete(node);
                clearTimeout(timeout);
            }
            return carry;
        }, []);

        trackNodes();
    };

    updateHandler = update;

    const destroy = () => {
        root.removeEventListener('scroll', scrollHandler);
        observer.disconnect();
        observer = null;
        nodes.forEach(node => node.removeAttribute('data-revealed'));
        gsap.killTweensOf(nodes);
        gsap.set(nodes, { clearProps: 'all' });
    };

    return {
        init,
        update,
        destroy,
        tweens
    };

};
