import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);
    let embed = $el.find('[data-embed]').get(0);

    if (!embed) {
        return null;
    }

    $(embed).html(JSON.parse(embed.dataset.embed).embedCode);

    const iframe = $(embed).find('iframe').get(0);

    const resize = () => {
        const { width, height } = el.getBoundingClientRect();
        const aspect = width / height;
        const videoWidth = parseInt(embed.dataset.width, 10);
        const videoHeight = parseInt(embed.dataset.height, 10);
        const videoAspect = videoWidth / videoHeight;
        if (videoAspect > aspect) {
            $(embed).css({ height: `${height}px`, width: `${height * videoAspect}px`, top: 0, left: `${(width - (height * videoAspect)) / 2}px`, maxWidth: 'none' });
        } else {
            $(embed).css({ width: `${width}px`, height: `${width / videoAspect}px`, left: 0, top: `${(height - (width / videoAspect)) / 2}px`, maxWidth: 'none' });
        }
    };

    let Vimeo;
    let player;

    const onTimeout = () => {
        timeout = null;
        if (embed) {
            $(embed).remove();
            embed = null;
        }
        if (player) {
            player.destroy();
            player = null;
        }
        $(el).find('.lzld').addClass('lazyload lazypreload').removeClass('lzld');
    };

    let timeout = setTimeout(onTimeout, 3000);

    const cancelTimeout = () => {
        if (!timeout) {
            return;
        }
        clearTimeout(timeout);
        timeout = null;
    };

    const onPlayerTimeUpdate = () => {
        if (!embed || !player) {
            return;
        }
        try {
            player.getCurrentTime().then(time => {
                if (time <= 0.01) {
                    return;
                }
                cancelTimeout();
                gsap.to(embed, { opacity: 1, duration: 0.5, ease: 'Cubic.easeIn' });
                player.off('timeupdate', onPlayerTimeUpdate);
            }).catch(error => {
                onTimeout();
            });
        } catch (error) {
            onTimeout();
        }
    };

    let vimeoPoller = setInterval(() => {
        console.log('polling for Vimeo');
        Vimeo = window.Vimeo || null;
        if (Vimeo) {
            console.log('got Vimeo!');
            clearInterval(vimeoPoller);
            vimeoPoller = null;
            try {
                player = new Vimeo.Player(iframe);
                player.ready().then(() => {
                    player.on('timeupdate', onPlayerTimeUpdate);
                });
            } catch (error) {
                onTimeout();
            }
        }
    }, 10);

    resize();

    Viewport.on('resize', resize);

    const destroy = () => {
        Viewport.off('resize', resize);
        cancelTimeout();
        if (vimeoPoller) {
            clearInterval(vimeoPoller);
            vimeoPoller = null;
        }
        if (player) {
            player.destroy();
        }
    };

    return {
        destroy
    };

};
